@import "../../../../../styles/flex";
@import "../../../../../styles/dimentions";
@import "../../../../../styles/card";

.graphicDataContainer {
    @extend %card;
    width: 60%;
    position: relative;

    @media (max-width: $tablet) {
        width: 100%;
    }

    .loading {
        position: absolute;
        top: 50%;
    }
}

.graphicSelector {
    @include flex(row, flex-start, flex-start);
    width: 100%;

    .listSelectContainer {
        margin: 0 10px;
        cursor: pointer;
        padding: 0;
        border: none;

        label{
            display: none;
        }
        .example-custom-input {
            cursor: pointer;
            padding: 0;
            margin: 0;
            color: $gray;
            font-weight: bold;
        }
    }
}

