@import "../../../../styles/dimentions";
@import "../../../../styles/flex";
@import "../../../../styles/colors";

.usersTableContainer {
	padding: 20px;

	@media (max-width: $tablet) {
		padding: 0;
	}
}

.pageTableOnFilter {
	overflow: auto;
}




