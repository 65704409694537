@import "../../../styles/dimentions";
@import "../../../styles/flex";
@import "../../../styles/card";
@import "../../../styles/colors";

.licensedContainer {
    padding: 20px;

    @media (max-width: $tablet) {
        padding: 0;
    }

    .licensedWrapper {
        @include flex(column, center, center);

        @extend %card;

        width: 100%;
        box-sizing: border-box;
        padding: 30px;

        @media (max-width: $mobile) {
            padding: 15px;
        }

        .licensedTitle {
            @include flex(row, flex-start, center);
            width: 100%;
            padding: 0 20px;

            @media (max-width: $tablet) {
                text-align: center;
            }

            h1 {
                margin-right: 20px;
            }
        }

        .licensedContent {
            @include flex(column, flex-start, flex-start);
            width: 100%;
            padding: 0 20px;

            @media (max-width: $mobile) {
               padding: 0;
            }

            .licensedBlock {
                width: 100%;
                margin: 0 20px 20px 20px;

                @media (max-width: $mobile) {
                    margin: 0;
                }

                .balance {
                    @include flex(row, flex-start, flex-start);
            
                    width: 80%;
            
                    .balanceTitle {
                        width: 50%;
                        padding: 5px 25px;
                        color: $primary-color;
                    }
            
                    .balanceAmount {
                        width: 50%;
                    }
                }
            
                .balance.strong {
                    font-weight: bold;
                }
            
                .buttonArea {
                    width: 100%;
                    text-align: end;
                    @media (max-width: $mobile) {
                        padding: 0 10px;
            
                        width: 100%;
                        margin-top: 15px;
            
                        button {
                            width: 100%;
                        }
                    }
                }

                .licensedLine {
                    margin-top: 20px;

                    .licensedDetail {
                        width: 100%;
                    }

                    .franchisePerItemFields {
                        @include flex(row, flex-start, flex-start);
                        width: 100%;
                        flex-wrap: wrap;

                        .licensedPerItemField {
                            @include flex(column, space-between, flex-start);
                            width: 100%;
                            margin: 20px 20px 0 20px;
                            border: 1px solid $primary-color;
                            border-radius: 5px;
                            padding: 10px;

                            .licensedLine {
                                .licensedDetail {
                                    width: 100%;
                                }
                            }

                            h3 {
                                margin-top: 0;
                            }
                        }
                    }

                    .buttonEditOrAddAccount {
                        width: 100%;
                        text-align: end;

                        @media (max-width: $mobile) {
                            width: 100%;
                            margin-top: 15px;

                            button {
                                width: 100%;
                            }
                        }
                    }
                }

                .licensedLine {
                    @include flex(column, flex-start, center);
                    width: 100%;

                }

                .licensedDetail {
                    @include flex(row, flex-start, center);
                    width: 100%;

                    .bank {
                        @include flex(column, flex-start, center);
                        width: 80%;
                        margin: 0 auto;

                        @media (max-width: $mobile) {
                            @include flex(column, flex-start, flex-start);
                            width: 100%;

                        }

                        .boxDetails{
                            @include flex(row, flex-start, flex-start);
                            width: 70%;

                            @media (max-width: $mobile) {
                                width: 100%;
                            }

                            .bankTitle {
                                @include flex(row, flex-start, flex-start);
                                width: 50%;
                                font-size: 18px;
                                color: $primary-color;
                                font-weight: bold;
                                margin: 10px 0 0;
                            }
                            .bankDetails {
                                @include flex(row, flex-start, flex-start);
                                width: 50%;
                                font-size: 16px;
                                color: $text-color;
                                font-weight: bolder;
                                margin: 10px 0 0;
                            }
                        }

                    }
                    label {
                        margin-right: 10px;
                        color: $primary-color;
                    }

                    p {
                        margin: 0;
                    }

                }
            }
        }
    }
}
