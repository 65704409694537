@import "../../styles/flex";
@import "../../styles/colors";
@import "../../styles/dimentions";

.transferContentContainer {
    @include flex(column, flex-start, flex-start);

    width: 100%;
    padding: 0 20px;
}
