@import "../../../../styles/dimentions";
@import "../../../../styles/flex";
@import "../../../../styles/card";

.itemDetailsContainer {
    padding: 20px;

    @media (max-width: $tablet) {
        padding: 0;
    }
}
.itemDetailsWrapper {
    @include flex(column, flex-start, flex-start);

    @extend %card;

    width: 80%;
    box-sizing: border-box;
    padding: 30px;
    margin: 30px;

    @media (max-width: $tablet) {
        @include flex(column, center, center);
        width: 100%;
        margin: 0;
    }
}
.itemDataContainer {
    @include flex(row, space-between, flex-start);

    box-sizing: border-box;
    width: 100%;

    @media (max-width: $tablet) {
        @include flex(column, space-between, flex-start);
    }
}
.itemContainerImages {
    @include flex(row, center, center);

    box-sizing: border-box;

    @media (max-width: $tablet) {
        @include flex(column, flex-start, flex-start);
        width: 100%;
    }
}
.itemImage {
    box-sizing: border-box;
    margin-right: 10px;
    width: 90%;
    height: 250px;
    overflow: hidden;
    position: relative;
    img {
        position: absolute;
        bottom: 0;
        width: 100%;
    }
    @media (max-width: $tablet) {
        width: 150px;
        height: 150px;

    }
}
.itemContainerData {
    @include flex(column, center, flex-start);

    box-sizing: border-box;
    width: calc(100% - 100% / 2);
    margin-left: 20px;

}
.itemDetailAvatar {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: relative;
    img {
        position: absolute;
        bottom: 0;
        width: 100%;
    }
    @media (max-width: $tablet) {
        width: 150px;
        height: 150px;
        margin-bottom: 10px;
    }
}
.itemRow {
    @include flex(row, flex-start, flex-start);
    flex-wrap: wrap;
    width: 100%;

}
.itemTwoColumns {
    box-sizing: border-box;
    width: calc(100% / 2);

    @media (max-width: $tablet) {
        width: 30%;
    }
    @media (max-width: $mobile) {
        width: 100%;
    }

}
.itemTreeColumns {
    @include flex(column, flex-start, flex-start);

    box-sizing: border-box;
    width: calc(100% / 3);

    @media (max-width: $tablet) {
        width: 50%;
    }
    @media (max-width: $mobile) {
        width: 100%;
    }
}
.itemTreeColumns.image {

    @media (max-width: $tablet) {
        width: 100%;
    }
}
.itemDetailsCard {
    box-shadow: 1px 2px 1px rgba(0, 0, 0, .2);
}
.itemDetailsTitle {
    @include flex(column, flex-start, flex-start);

    width: 100%;
}


