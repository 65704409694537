@import "../../styles/colors";

.textFieldContainer {

  * {
    border-color: $white;
    color: $white;
  }

  .errorTypography {
    color: red !important;
  }
}
