@import "../../styles/colors";

.loginLayoutContainer {
	background-color: $background-color;

	width: 100%;
	height: 100%;

	padding: 0;
	margin: 0;

	&ChildrenContainer {
		width: 100%;
		height: 100%;

		padding: 0;
		margin: 0;
	}
}
