@import "../../../../../styles/dimentions";
@import "../../../../../styles/flex";
@import "../../../../../styles/card";
@import "../../../../../styles/colors";


.buttonsContainer {
    @include flex(row, center, center);
    width: 100%;

    padding: 20px 0;
    overflow: auto;

    @media (max-width: $mobile) {
        @include flex(row, flex-start, center);
        margin: 10px 0;
    }
    .componentButton {
        @extend %base_card;
        width: 150px;
        height: 74px;
        margin: 0 10px;
        cursor: pointer;

        .descriptionLabel {
            color: $gray;
            font-size: 14px;
        }

        @media (max-width: $mobile) {
            min-width: 130px;
            width: auto;
            height: auto;
        }

            @media (max-width: $mobile) {
            .descriptionDate {
                @include flex(row, center, flex-start);
            }
            .descriptionLabel {
               display: none;
            }
            .componentButtonFooter  {
                display: none;
            }
		}
    }

    .selectedButton {
        @extend .componentButton;
        background-color: $primary-color;

        .descriptionDate {
            color: white;
        }
        .descriptionLabel {
            color: white;
        }
        .percentage {
            font-size: 12px;
            color: white;
        }
    }
    .selectedButtonModal {
        @extend .componentButton;
        background-color: $primary-color;

        .descriptionDate {
            color: white;;
        }
        .descriptionLabel {
            color: white;
        }
        .percentage {
            font-size: 12px;
            color: white;
        }

    }
    
}
