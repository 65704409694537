@import "../../../../styles/flex";
@import "../../../../styles/card";
@import "../../../../styles/colors";
@import "../../../../styles/dimentions";

.bannerCreateOrEdit {
	@include flex(column, flex-start, flex-start);

	width: 100%;
	&Wrapper {
		@include flex(column, center, center);
		@extend %card;
		
		box-sizing: border-box;
		margin: 20px auto;
		width: 80%;

		color: $primary-color;

		@media (max-width: $mobile) {
			width: 100%;
			margin: 0;
		}
		
		@media (max-width: $tablet) {
			width: 100%;
			margin: 0;
		}
		
		.bannerCreateOrEditHeader {
			width: 100%;
			@include flex(row, space-between,flex-start);

			.bannerCreateOrEditTitle {
				text-align: center;
				font-weight: bolder;
				color: $primary-color;
			}
			
		}

	
		.bannerFormContainer {
			width: 100%;
	
			h2 {
				font-size: 18px;
			}

			.rowTwoEnum {
				@include flex(row, space-between, flex-start);
				width: 100%;
				.enum {
					width: 45%;

					.selectOptionsFormControl {
						padding: 0 !important;	
					}
				}
			}
		}
	
		.buttonsContainer {
			@include flex(row, flex-end, center);
	
			margin-top: 30px;
			width: 100%;

		}
	}

}
