@import "../../styles/colors";
@import "../../styles/flex";
@import "../../styles/dimentions";

.listSelectContainer {
	@include flex(row !important, space-between !important, center !important);
	box-sizing: border-box;
    border: 1px solid $primary-color;
	border-radius: 5px;
    margin: 16px 0 8px;
    padding: 18.5px 14px;

	@media screen and (max-width: $mobile) {
		align-items: flex-start;
		justify-content: flex-start;
	}
	label {
		display: block;
		width: 100%;
		color: $primary-color;
		text-align: left !important;
		font-size: 14px;
		font-weight: bold;

		@media screen and (max-width: $mobile){
			font-size: 12px !important;
		}
	}

	button {
		@include flex(row, center, center);

		background-color: transparent;
		color: $black;
		text-align: center;
		font-size: 14px !important;
		border: 0;
		padding: 0;
		outline: 0;

		@media screen and (max-width: $mobile){
			font-size: 12px !important;
		}
	}

	.errorTypography {
		color: red;
	}
}
