@import "../../styles/colors";
@import "../../styles/flex";
@import "../../styles/dimentions";

.dateSelectContainer {
	@include flex(row, space-between, center);
	border: 1px solid $primary-color;
	border-radius: 5px;
	margin: 16px 0 8px;
	padding: 18.5px 14px;
	text-align: center;

	
	label {
		display: block;
		width: 60%;
		color: $primary-color;
		text-align: left;
		font-size: 16px;
		font-weight: 400;

		@media screen and (max-width: $mobile){
			font-size: 14px !important;
		}
	}

	.datePicker {
		@include flex(row, flex-end, center);

		width: 100%;
		background-color: transparent;
		color: $black;
		text-align: right;
		font-size: 16px;
		border: 0;
		outline: 0;

		@media screen and (max-width: $mobile){
			display: none;
		}
	}

	.datePickerMobile {
		@extend .datePicker;
		display: none;

		@media screen and (max-width: $mobile){
			display: flex;
			font-size: 12px;
		}
	}



	.errorTypography {
		color: red;
	}
}
