@import "../../styles/colors";
@import "../../styles/flex";
@import "../../styles/card";
@import "../../styles/dimentions";

.locationCity {
  @include flex(column, center, center);
  width: 100%;

  @media (max-width: $tablet) {
    height: 100%;
  }

    .selectCities {
      @include flex(column, flex-start, flex-start);
      width: 100%;

      .button {
        outline: none;
      }
    }

  }

.containerSelectLocation {
  @include flex(column, center, center);
  width: 100%;
  margin-top: 20px;
}
.locationTitle {
  color: $primary-color;
  text-align: center;
  margin : 30px 0;
  font-size: 18px;
  font-weight: 100;
  text-transform: uppercase;
}
.searchCity {
  width: 100%;

  .selectOptionsFormControl {
    padding: 0 !important;
  }
}


