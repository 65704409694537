@import "../../../../../../styles/dimentions";
@import "../../../../../../styles/flex";
@import "../../../../../../styles/colors";
@import "../../../../../../styles/card";


.usersField {
    @include flex(column, flex-start, flex-start);
    width: calc(50% - 10px);

    height: 240px;
                                
    @extend %card;

    @media (max-width: $tablet) {
        width: 100%;
        margin: 10px 0;
        height: auto;
    }

    .loadingField {
        @include flex(row, center, center);
        width: 100%;
        height: 100%;
    
    }

    .listTopTitle {
        @include flex(row, space-between, flex-start);

        width: 100%;
        margin-bottom: 10px;

        p {
            text-align: start;
            font-size: 14px;
            font-weight: bold;
            color: $text-color;
            margin: 0;

            @media (max-width: $tablet) {
                font-size: 18px;
            }
        }
    }
    .user {
        @include flex(row, space-between, flex-start);
        width: 100%;

        @media (max-width: $tablet) {
            font-size: 16px;
        }
    }
}

