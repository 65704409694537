@import "../../../../styles/flex";
@import "../../../../styles/card";
@import "../../../../styles/colors";
@import "../../../../styles/dimentions";

.offerCreateOrEdit {
	@include flex(column, flex-start, flex-start);

	width: 100%;
	&Wrapper {
		@include flex(column, center, center);
		@extend %card;
		
		box-sizing: border-box;
		margin: 20px auto;
		width: 80%;

		color: $primary-color;

		@media (max-width: $mobile) {
			width: 100%;
			margin: 0;
		}

		@media (max-width: $tablet) {
			width: 100%;
			margin: 0;
		}
	
		.createOrEditTitle {
			font-weight: bolder;
			color: $primary-color;
			text-align: start;
			width: 100%;
		}
	
		.offerFormContainer {
			width: 100%;
	
			h2 {
				font-size: 18px;
			}
		}
	
		.buttonsContainer {
			@include flex(row, flex-end, center);
	
			margin-top: 30px;
			width: 100%;

			button {
				margin-left: 20px;
			}
		}
	}

}
