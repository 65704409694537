@import "../../../../styles/flex";
@import "../../../../styles/colors";
@import "../../../../styles/card";

.editTableContainer {
    @include flex(column, flex-start, flex-start);

    width: 100%;
    padding: 0;
    box-sizing: border-box;
    
    &DragDrop {
        @include flex(row, space-between, flex-start);
        flex-wrap: wrap;
        width: 100%;
        box-sizing: border-box;
        
        * {
            margin: 8px;
        }
        @media (max-width: $mobile) {
            @include flex(column, flex-start, flex-start);

          
        }
    }
}

.columnView {
    @extend %card;
    margin: 0;
    padding: 16px;
    width: calc(50% - 10px);
    border-radius: 4px;


    @media (max-width: $mobile) {
        width: 100%;
        margin-bottom: 10px;
    }
}

.columnDraggingView {
    @extend .columnView;
   // background-color: rgba($primary-color, 0.7);
}

.columnRowContainer {
    user-select: none;
    text-align: center;
    width: 100%;
    padding: 16px;
    margin: 5px 0;
    border: 1px solid $light-gray;
    border-radius: 4px;
    color: $primary-color;
}

.columnRowDraggingContainer {
    @extend .columnRowContainer;
    background-color: $primary-color;
    color: $white;
    
}
.buttonField {
    @include flex(row, flex-end, flex-start);
    width: 100%;

    padding-top: 10px;;
}