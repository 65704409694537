@import "../../../../styles/flex";
@import "../../../../styles/colors";
@import "../../../../styles/dimentions";

.registerPageContainer {
    @include flex(column, flex-start, center);
    box-sizing: border-box;
    width: 100%;
    padding: 20px;

    .registerBox {
        @include flex(column, flex-start, center);
        box-sizing: border-box;
        width: 100%;
        max-width: 800px;
        padding: 20px;
        box-shadow: 0px 2px 10px -3px rgba(0,0,0,0.75);
        border-radius: 5px;

        @media(max-width: $mobile) {
            box-shadow: none;
            margin-bottom: 80px;
            padding: 0;
        }

        .title {
            margin: 20px 0;
            color: $primary-color;

            @media(max-width: $mobile) {
                font-size: 24px;
            }
        }

        .registerForm {
            @include flex(column, flex-start, flex-start);
            width: 100%;                

            .textFieldContainer, .listSelectContainer {
                width: 100%;

                @media(max-width: $mobile) {
                    font-size: 12px;
                }
            }
            
            .twoFields {
                @include flex(row, space-between, center);
                width: 100%;

                .selectOptionsFormControl {
                    padding: 0 !important;
                }

                @media(max-width: $tablet) {
                    @include flex(column, center, flex-start);
                }

                .dateSelectContainer, .listSelectContainer, .textFieldContainer, .selectOptionsFormControl {
                    width: 47.5%;

                    @media(max-width: $tablet) {
                        width: 100%;
                    }
                }
            }

            .licenseField {
                @include flex(column, center, space-between);
                width: 100%;
            
                .addLicense {
                    @include flex(column, space-between, center);
                    width: 100%;
            
                    @media(max-width: $mobile) {
                        @include flex(column, center, flex-start);
                    }
            
                    .fields {
                        @include flex(column, center, space-between);
                        width: 72.5%;
                
                        .dateSelectContainer {
                            .react-datepicker-wrapper {
                                width: 40%;
                                
                                .datePicker {
                                    font-size: 14px;
                                }
                            }
                        }

                        @media(max-width: $mobile) {
                            width: 100%;
                        }
                    }
            
                    .addButton {
                        border: none;
                        border-radius: 5px;
                        cursor: pointer;
                        padding: 10px 30px;
                        color: $white;
                        background-color: $primary-color;
                        width: 22.5%;
                        padding: 18.5px 14px;
                        font-size: 12px;
            
                        @media(max-width: $mobile) {
                            width: 100%;
                        }
                    }
                }

            }
        }

        .buttonArea {
            @include flex(row, center, center);
            width: 100%;
            padding: 10px;
            
            @media(max-width: $mobile) {
                padding: 20px;
            }

            .registerButton {
                border: none;
                border-radius: 5px;
                cursor: pointer;
                padding: 10px 30px;
                color: $white;
                background-color: $primary-color;
                font-size: 20px;

                @media(max-width: $mobile) {
                    font-size: 18px;
                }
            }
        }

        .showLicenses {
            @include flex(row, space-between, center);
            width: 100%;

            .section {
                @include flex(row, center, center);
                width: 100%;

                .title {
                    font-size: 18px;
                }
                .info {
                    font-size: 16px;
                }
                .buttonRemoveLicense {
                    font-size: 14px;
                    padding: 7px 26px;
                }
            }
        }
    }
}
