@import "../../../../styles/dimentions";
@import "../../../../styles/flex";
@import "../../../../styles/colors";
@import "../../../../styles/card";

.salesContent {
    @include flex(column, flex-start, flex-start);
    width: 100%;
    padding: 20px;

    @media (max-width: $tablet) {
      margin: 0;
      padding: 10px 10px 10px;
    }

    .salesBlock {

        width: 100%;

      

        .card {
            @extend %base_card;
            margin: 0 20px 0 0;

            @media (max-width: $mobile) {
                width: calc( 100% / 2 - 20px);
                margin: 0;
            }
            
        }
    }

    .salesDetailLine {
        @include flex(row, flex-start, flex-start);
        width: 100%;

        @media (max-width: $mobile) {
            @include flex(row, center, center);
            margin: 0;

            flex-wrap: wrap;
            .componentButton {
                @include flex(column, space-around, flex-end);

                margin: 10px;


                .descriptionDate {
                    font-size: 16px;
                    
                }
            }
        }
    
    }

    .salesDetail {
        @include flex(row, flex-start, center);
        
        width: 45%;
        
        @media (max-width: $tablet) {
            @include flex(column, flex-start, flex-start);
            width: 45%;
            margin-bottom: 20px;
        }

        label {
            margin-right: 10px;
            color: $primary-color;

            @media (max-width: $tablet) {
                font-size: 14px;
                margin: 0;
            }
        }

        p {
            margin: 0;
        }
    }
}
