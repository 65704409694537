@import "../../../styles/colors";
@import "../../../styles/dimentions";
@import "../../../styles/flex";

// AppBar
.appBar {
    height: $navbar-height;
    background-color: $white;

    &Toolbar {
        background-color: $white;
        height: 100%;
    }
}

.appToolBarAround {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.appToolBarDropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.appBarToolbarImage {
    width: 10%;
    max-height: 60%;
    margin-left: 20px;
}

.appBarPlaceholder {
    height: $navbar-height;
    background-color: $white;
}

// Drawer
.sidedrawer {
    &CloseButtonContainer {
        @include flex(row, flex-end, center);

        width: 100%;
        padding-right: 10px;
        padding-top: 10px;
    }
}
