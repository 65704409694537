@import "../../../../styles/dimentions";
@import "../../../../styles/flex";
@import "../../../../styles/colors";

.requestsContainer {
    padding: 20px;

    @media (max-width: $tablet) {
        padding: 0;
    }
}
.badgesView {
    @include flex(row, flex-start, flex-start);
    width: 100%;
    padding: 0 24px;
    flex-wrap: wrap;
}
