@import "../../../styles/dimentions";
@import "../../../styles/flex";
@import "../../../styles/card";
@import "../../../styles/colors";

* {
    box-sizing: border-box;
}

.reportContainer {
    padding: 20px;

    @media (max-width: $tablet) {
        padding: 0;
    }

    .reportWrapper {
        @include flex(column, flex-start, flex-start);

        @extend %card;

        width: 80%;
        padding: 30px;
        margin: 30px;

        @media (max-width: $tablet) {
            @include flex(column, center, center);
            width: 100%;
            margin: 0;
        }

        .reportTitle {
            width: 100%;
            color: $primary-color;

            h1 {
                margin: 0
            }
        }
        .containerSelect {
            @include flex(row, flex-start, center);
            width: 100%;


            @media (max-width: $mobile) {
                @include flex(column, center, center);
            }
        }
        .select {
            @include flex(column, flex-start, flex-start);
    
            width: 25%;
            height: 100%;
            margin: 0 0 0 20px;

            @media (max-width: $mobile) {
                width: 100%;
                margin: 0 0 20px 0;
            }

            .selectOptionsFormControl {
                padding: 0 !important;
            }
        }

        .graphsContainer {
            width: 100%;    
            padding: 20px;
        }

        .reportContent {
            @include flex(column, flex-start, flex-start);
            width: 100%;
            margin-left: 20px;

            @media (max-width: $tablet) {
              margin: 0;
            }

            .reportBlock {

                width: 100%;

                .reportFilterButton {
                    margin: 20px 0;
                }

            }

            .reportDetailLine {
				@include flex(row, space-between, flex-start);
                width: 100%;
                margin-bottom: 20px;
				
			}

            .reportDetail {
                @include flex(row, flex-start, center);
                
                width: 45%;
                
                @media (max-width: $tablet) {
                    @include flex(column, center, center);
                    width: 45%;
                }

                label {
                    margin-right: 10px;
                    color: $primary-color;

                    @media (max-width: $tablet) {
                        font-size: 14px;
                        margin: 0;
                    }
                }

                p {
                    margin: 0;
                }
            }
        }
    }
}
