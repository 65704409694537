@import "../../styles/colors";
@import "../../styles/flex";
@import "../../styles/card";

.login {
	@include flex(column, center, center);

	width: 100%;
	height: 100%;

	&Card {
		@extend %card;

		min-width: 20%;

		&Image {
			max-width: 250px;
			margin: 20px;
		}

		&Button {
			margin: 15px;
			min-width: 60%;
		}
		
	}
	.recoveryLink {
		background-color: transparent;
		color: $primary-color;
	}
}
