@import "../../../../../styles/dimentions";
@import "../../../../../styles/flex";
@import "../../../../../styles/card";
@import "../../../../../styles/colors";


.detailsContainer {
    * {
        box-sizing: border-box;
    }

    @include flex(column, flex-start, flex-start);
    width: 100%;


    @media (max-width: $tablet) {
        @include flex(row, flex-start, center);
    }

    .firstField {
        @include flex(row, flex-start, flex-start);
        width: 100%;
        flex-wrap: wrap;
        padding:  20px;

        @media (max-width: $mobile) {
            padding: 10px 20px 0;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
        }

    }
    .componentButton {
        @include flex(column, space-around, flex-end);
        @extend %card;
        margin: 0 10px 10px 0;
        padding: 10px;


        .descriptionLabel {
            font-size: 20px;
            font-weight: bold;
        }

        @media (max-width: $tablet) {
            width: calc(50% - 10px);
            margin: 5px;

        }
    }

}

