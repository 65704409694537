
.itemDetail {
	box-sizing: border-box;
	margin: 0 0 7px;
	padding: 0 2px;
	h3.title {
		margin: 0 0 7px;
		padding: 0;
	}
	.info {
		margin: 0;
		font-weight: 200;
	}
}
