@import "../../../../styles/dimentions";
@import "../../../../styles/flex";
@import "../../../../styles/colors";
@import "../../../../styles/card";

.periodPicker {
    width: 100%;
    padding: 10px 0;

    .periodPickerRow {
        @include flex(row, flex-start, center);
        width: 100%;

        .periodPickerLabel {}
        .periodPickerSelect {
            text-decoration: underline;
            text-decoration-color: $light-gray;
        }
        .periodPickerClear {
            font-weight: bold;
            color: $error-color;
            cursor: pointer;
        }
        .periodPickerConfirm {
            background: $primary-color;
            color: $white;
        }
    }
}

.popoverPickerContainer {
    padding: 20px;
    min-width: 200px;
    min-height: 200px;

    .react-calendar {
        border: 0;
    }
}
