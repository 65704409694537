@import "../../styles/dimentions";
@import "../../styles/flex";
@import "../../styles/card";

.userDetailsContainer {
	@include flex(column, flex-start, flex-start);

	width: 100%;

	@media (max-width: $tablet) {
		padding: 0;
	}
}
.userDetailsWrapper{
	@include flex(column, flex-start, flex-start);

	@extend %card;

	width: 80%;
	box-sizing: border-box;
	padding: 30px;
	margin: 30px;

	@media (max-width: $tablet) {
		@include flex(column, flex-start, flex-start);
		width: 100%;
		margin: 0;
	}
	@media (max-width: $mobile) {
		@include flex(column, flex-start, flex-start);
		width: 100%;
		margin: 0;
	}
}

.userDataContainer {
	@include flex(row, space-between, flex-start);

	box-sizing: border-box;
	width: 100%;

	@media (max-width: $tablet) {
		@include flex(column, space-between, flex-start);
	}
}
.userContainerImage {
	@include flex(row, center, center);

	box-sizing: border-box;
	width: calc(100% / 3);
	flex-grow: 1;

	@media (max-width: $tablet) {
		@include flex(row, center, center);

		width: 100%;
		margin-bottom: 15px;
	}
}
.userDetailsAvatar {
	border-radius: 50%;
	width: 150px;
	height: 150px;
	overflow: hidden;
	position: relative;
	img {
		position: absolute;
		bottom: 0;
		width: 100%;
	}
	@media (max-width: $tablet) {
		width: 150px;
		height: 150px;
	}
}
.userContainerData {
	@include flex(column, center, flex-start);

	box-sizing: border-box;
	width: calc(100% - 100% / 3);

	@media (max-width: $tablet) {
		width: 100%;
	}
}

.userRow {
	@include flex(row, flex-start, flex-end);
	flex-wrap: wrap;
	width: 100%;

}

.userTwoColumns {
	box-sizing: border-box;
	width: calc(100% / 2);

	@media (max-width: $tablet) {
		width: 50%;
	}
	@media (max-width: $mobile) {
		width: 100%;
	}

}
.userTreeColumns {
	box-sizing: border-box;
	width: calc(100% / 3);

	@media (max-width: $tablet) {
		width: 50%;

	}
	@media (max-width: $mobile) {
		width: 100%;
	}

}

.userContacts {
	@include flex(row, space-between, flex-start);

	width: 100%;
	margin-top: 20px;
}
