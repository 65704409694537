@import "../../../../../styles/flex";
@import "../../../../../styles/dimentions";

.containerEditAddress {
  @include flex(column, center, center);
  width: 100%;

  .title {
    width: 100%;
    text-align: start;
  }

  .inputsContainer {
    @include flex(column, flex-start, flex-start);
    width: 100%;
    box-sizing: border-box;

    .textFieldContainer {
      width: 100%;
    }

  }
}

