@import "../../styles/flex";

.header {
    @include flex(row, space-between, center);

    padding-right: 24px;


}
.content {
    padding: 0 62px 24px !important;
}
