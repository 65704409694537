@import "../../../../styles/dimentions";
@import "../../../../styles/flex";
@import "../../../../styles/card";

.requestDetailsContainer {
    @include flex(column, flex-start, flex-start);

    @media (max-width: $tablet) {
        padding: 0;
    }
}

.requestDetailsCard {
    @include flex(column, flex-start, flex-start);

    @extend %card;

    width: 80%;
    box-sizing: border-box;
    padding: 30px;
    margin: 30px;

    @media (max-width: $tablet) {
        @include flex(column, center, center);
        width: 100%;
        margin: 0;
    }
    @media (max-width: $mobile) {
        @include flex(column, flex-start, flex-start);
        width: 100%;
        margin: 0;
    }
}

.requestTwoColumns {
    box-sizing: border-box;

    @media (max-width: $tablet) {
        width: 30%;
    }
    @media (max-width: $mobile) {
        width: 100%;
    }

}

.textContainer {
    @include flex(column, flex-start, space-between);

    width: 70%;
    height: 100%;
    flex-wrap: wrap;

    @media (max-width: $mobile) {
        @include flex(row, space-between, center);

        width: 50%;
        height: 300px;
    }
}
.cardTitle {
    @media (max-width: $tablet) {
        width: 100%;
        text-align: left;
    }
}
