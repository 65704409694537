@import "../../styles/flex";
@import "../../styles/colors";
@import "../../styles/dimentions";

.arrayField {
    @include flex(column, center, space-between);
    width: 100%;

    .addField {
        @include flex(row, space-between, center);
        width: 100%;

        @media(max-width: $mobile) {
            @include flex(column, center, flex-start);
        }

        .child {
            width: 100%;
        }

        .addButton {
            padding: 15px 15px;
            margin: 15px;
            width: 100%;
            max-width: 200px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            color: $white;
            background-color: $primary-color;
            font-size: 12px;

            @media(max-width: $mobile) {
                max-width: initial;
            }
        }
    }

    .arrayData {
        @include flex(row, flex-start, flex-start);
        flex-wrap: wrap;
        width: 100%;
        padding: 10px 0;

        .metaTagChip {
            width: fit-content;
            margin: 2px;
        }
    }
}
