@import "../../../../styles/dimentions";
@import "../../../../styles/flex";
@import "../../../../styles/colors";

.salesContainer {
	@include flex(row, flex-start, flex-start);
    box-sizing: border-box;
    height: calc(100vh - 70px);

    .salesFilter {
        @include flex(column, flex-start, flex-start);
        box-sizing: border-box;
        position: absolute;

        height: 100%;
        padding: 20px;
        width: 250px;
        left: 0;
        transition: width 1s, left 1s;

        &Closed {
            @extend .salesFilter;

            transition: left 1s;
            left: -250px;
        }
    }

    .salesTable {
        @include flex(column, flex-start, flex-start);
        padding-left: 250px;
        width: 100%;
        height: 100%;
        transition: padding-left 0.3s;

        &Full {
            @extend .salesTable;
            padding-left: 0;
            transition: padding-left 0.3s;
        }
    }

    .table {
        width: 100%;
        height: 100%;
        padding: 0 10px 10px;

        & > div {
            height: 100%;

            .overflowX {
                height: calc(100% - 140px);
            }

        }

        .toolbar {
            &Icon {
                margin: 5px;
                cursor: pointer;
                transition: padding 0.3s, background-color 0.3s;

                &Selected {
                    @extend .toolbarIcon;

                    background-color: $light-gray;
                    padding: 5px;
                    transition: padding 0.3s, background-color 0.3s;
                }
            }
        }
    }

}
.saleTableHeaderViewContainer {
    @include flex(column, center, center);
    margin: -18.5px -14px;

    @media (max-width: $mobile) {
        width: 100%;
        margin: 0;
    }
}
