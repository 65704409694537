@import "../../styles/flex";
@import "../../styles/dimentions";

.saveAndCancelButtonsContainer {
	@include flex(row, flex-end, flex-end);
	width: 100%;
	margin-top: 20px;

	.buttonSave {
		padding: 2px;
		border-radius: 4px;
		button span {
			color: #ffffff !important;
		}
		background-color: #fc6371;
	}
	.buttonCancel button span {
		text-align: center;
		color: #fc6371 !important;
		margin-left: 10px;
	}
}
