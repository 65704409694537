@import "../../styles/dimentions";
@import "../../styles/flex";
@import "../../styles/colors";

.pageTableContainer {
	@include flex(row, flex-start, flex-start);
    box-sizing: border-box;
    height: calc(100vh - 70px);

    .pageTableFilter {
        @include flex(column, flex-start, flex-start);
        box-sizing: border-box;
        position: absolute;

        height: calc(100% - 70px);
        padding: 22px 20px 20px ;
        width: 280px;
        left: 0;
        z-index: 999;
        transition: width 0.3s, left 0.3s;
        overflow-y: auto;

        @media (max-width: $mobile) {
           width: 100%;

        }

        .pageFilterContainer {
            position: relative;
            width: 100%;
            height: 100%;
        }

        &Closed {
            @extend .pageTableFilter;

            transition: left 0.3s;
            left: -280px;

            @media (max-width: $mobile) {
                left: -100%;

            }

        }

    }

    .pageTable {
        width: 100%;
        height: 100%;
        transition: padding-left 0.3s;

        .badgesView {
            @include flex(row, flex-start, flex-start);
            width: 100%;
            padding: 20px 14px ;
            flex-wrap: wrap;

            .metaTagChip {
                margin: 0 10px 0 0;
            }
        }
        .editTableView {
            @include flex(row, center, center);
            width: 100%;;
            padding: 0 20px  20px 20px;
        }
        .table {
            width: 100%;
            height: 100%;
            padding: 20px;

            .metaTagChip {
                margin: 5px;
            }
            & > div {
                height: 100%;
                .overflowX {
                    height: calc(100% - 140px);
                }


            }

            .toolbar {
                @include flex(row, flex-start, flex-start);
                width: 100%;
                &Icon {
                    margin: 5px;
                    cursor: pointer;
                    transition: padding 0.3s, background-color 0.3s;
                    border-radius: 50%;

                    &Selected {
                        @extend .toolbarIcon;

                        background-color: $light-gray;
                        padding: 5px;
                        transition: padding 0.3s, background-color 0.3s;
                    }
                }
            }
        }

        &OnFilter {
            @extend .pageTable;
            padding-left: 280px;
            padding-bottom: 20px;
            transition: padding-left 0.3s;

            @media (max-width: $mobile) {
                display: none;
            }

            .table {
                height: calc(100vh - 210px);
            }
        }
    }


	.downloadIcon {
		* {
			color: $white;
		}
	}

}

.onClear {
    background-color: transparent;
    display: inline;
    color: $primary-color;
    font-size: 14px;
    margin: 0 auto;

    @media (max-width: $mobile) {
        display: none;
    }

}

.fieldButton {
    @include flex(row, center, center);
    width: 100%;
    margin-bottom: 20px;

    .onClear {
        background-color: transparent;
        color: $primary-color;
        font-size: 14px;
        display: none;
        @media (max-width: $mobile) {
            display: inline;
        }
    }


    .onClose {
        @extend .onClear;
        display: none;
        font-size: 14px;

        @media (max-width: $mobile) {
         display: inline;
        }
    }
}


.filterSection {
    margin-bottom: 10px;
    width: 100%;

    h4 {
        margin: 0 -5px;
    }


    .filterForm {
        @include flex(column, flex-start, center);
        width: 100%;

        .datePicker {

            input {
                padding: 15px 0 15px 5px;
            }
        }
    }
}

.submitFilterButtonDesktop {
    width: 100%;
    position: sticky;
    top: 5px;
    z-index: 50;

    @media (max-width: $mobile) {
        display: none;
    }
}

.submitFilterButtonMobile {
    @extend .submitFilterButtonDesktop;
    display: none;

    @media (max-width: $mobile) {
        display: inline;

    }
}
