@import "../../../../../styles/dimentions";
@import "../../../../../styles/flex";
@import "../../../../../styles/colors";
@import "../../../../../styles/card";

.listTopContent {
    @include flex(column, flex-start, flex-start);
    width: 100%;

    padding: 20px;

    @media (max-width: $tablet) {
       padding: 0;
    }

    h2 {
        margin: 0;
        padding: 20px;
    }
    .listTopBlock {
        width: 100%;
    }

    .listTopDetailLine {
        @include flex(row, space-between, flex-start);
        width: 100%;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }

        @media (max-width: $tablet) {
            @include flex(column, center, center);

            padding: 0 10px;
            margin-bottom: 0;
        }

        .listTopDetail {
            @include flex(row, flex-start, flex-start);
            
            width: 100%;

            &:last-child {
                @include flex(row, flex-end, flex-end);
                width: 30%;
            }

            label {
                margin-right: 10px;
                color: $primary-color;
    
                @media (max-width: $tablet) {
                    font-size: 14px;
                    margin: 0;
                }
            }
    
            .amout {
                color: $text-color;
                font-weight: lighter;
                margin: 0 10px;
    
                @media (max-width: $tablet) {
                    font-size: 14px;
                }
            }
            p {
                color: $text-color;
                font-weight: bold;
                margin: 0 10px;
    
                @media (max-width: $tablet) {
                    font-size: 14px;
                }
            }
        } 
    } 
}
