@import "../../../../styles/dimentions";
@import "../../../../styles/flex";
@import "../../../../styles/card";
@import "../../../../styles/colors";

.userDetailsContainer {
    @include flex(column, flex-start, flex-start);



    @media (max-width: $tablet) {
        padding: 0;
    }
}
.userDetailsWrapper{
    @include flex(column, flex-start, flex-start);

    @extend %card;

    box-sizing: border-box;
    padding: 30px;
    margin: 30px;

    @media (max-width: $tablet) {
        @include flex(column, center, center);
        width: 100%;
        margin: 0;
    }
    @media (max-width: $mobile) {
        @include flex(column, flex-start, flex-start);
        width: 100%;
        margin: 0;
    }
}

.userDataContainer {
    @include flex(row, flex-start, flex-start);

    box-sizing: border-box;
    width: 100%;

    @media (max-width: $tablet) {
        @include flex(column, flex-start, flex-start);
    }
}
.userContainerImage {
    @include flex(row, flex-start, center);

    box-sizing: border-box;
    flex-grow: 1;

    @media (max-width: $tablet) {
        @include flex(row, flex-start, center);
    }
}
.userDetailsAvatar {
    border-radius: 50%;
    height: 150px;
    overflow: hidden;
    position: relative;
    img {
        position: absolute;
        bottom: 0;
        width: 100%;
    }
    @media (max-width: $tablet) {
        height: 150px;
    }
}
.userContainerData {
    @include flex(column, center, flex-start);

    box-sizing: border-box;
}

.userRow {
    @include flex(row, flex-start, flex-end);
    flex-wrap: wrap;
    width: 100%;

}
.userTwoColumns {
    box-sizing: border-box;

    @media (max-width: $tablet) {
        width: 50%;
    }
    @media (max-width: $mobile) {
        width: 100%;
    }

}
.userTreeColumns {
    @include flex(column, space-between, flex-start);

    @media (max-width: $tablet) {
        width: 50%;
    }
    @media (max-width: $mobile) {
        width: 100%;
    }

}
.userContacts {
    @include flex(row, space-between, flex-start);

    margin-top: 20px;
}
.userDetailsTitle {
    width: 100%;
   text-align: left;
    margin-top: 30px;
}

.userItemsTable {
    margin-top: 20px;
    width: 100%;
}
