@import "../../../../../styles/flex";
@import "../../../../../styles/card";
@import "../../../../../styles/colors";
@import "../../../../../styles/dimentions";

.sectionItems {
	@include flex(column, flex-start, flex-start);
	//border: 1px solid $primary-color;

	@extend %card;

	padding: 10px 20px;
	margin: 40px 5px;
}

.sectionTitle {
	margin: 0;
	padding: 0;
	color: $primary-color;

}
.bannerSectionTitle {
	font-weight: bolder;
	margin-top: 35px;
	margin-bottom: 10px;
	color: $primary-color;
}
.title {
	font-weight: bolder;
	color: $primary-color;
	padding: 0;
}
