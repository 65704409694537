@import "../../../styles/dimentions";

.footerDiv {
    padding: 10px;
    padding-bottom: 14px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media (max-width: $mobile) {
        .MuiButton-root-162 {
            margin-right: 0px;
            margin-left: 0px;
        }

        .MuiFormControlLabel-root-188 {
            margin-left: 5%;
            margin-right: 0px;
        }
    }

    .paginationControl {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}
