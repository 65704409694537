@import "../../styles/colors";
@import "../../styles/flex";
@import "../../styles/dimentions";

.listSelectContainer {
	@include flex(column, center, center);

	text-align: center;

	@media screen and (max-width: $mobile) {
		align-items: flex-start;
		justify-content: flex-start;
	}
	label {
		display: block;
		width: 100%;
		color: $primary-color;
		text-align: center;
		font-size: 14px;
		font-weight: bold;

		@media screen and (max-width: $mobile){
			font-size: 12px !important;
		}
	}

	button {
		@include flex(row, center, center);

		background-color: transparent;
		color: black;
		text-align: center;
		font-size: 20px;
		border: 0;
		outline: 0;

		@media screen and (max-width: $mobile){
			font-size: 12px !important;
		}
	}

	.errorTypography {
		color: red;
	}
}
