@import "../../styles/flex";
@import "../../styles/card";
@import "../../styles/colors";
@import "../../styles/dimentions";

.overflowX {
    overflow-x: auto;
    white-space: nowrap;
}

.material-table > thead > tr > th {
    background: $white !important;
}
