@import "../../../styles/colors";
@import "../../../styles/flex";
@import "../../../styles/card";
@import "../../../styles/dimentions";

.bankAccountContainer {
  padding: 20px;

  @media (max-width: $tablet) {
    padding: 0;
  }

  .bankAccountWrapper {
    @include flex(column, center, center);

    @extend %card;

    width: 100%;
    box-sizing: border-box;
    padding: 30px;

    @media (max-width: $mobile) {
      padding: 15px;
    }
  }
  .bankAccountForm {
    @include flex(column, center, center);

    @extend %card;

    width: 100%;
    max-width: 600px;
    padding: 0 20px;
    margin-bottom: 40px;

    .title {
      color: $primary-color;
      padding: 0 10px;
    }

    .textFieldContainer, .selectOptionsFormControl {
      width: 100%;
      padding: 14px 0;

      * {
        border-color: $primary-color !important;
        @media(max-width: $mobile) {
          font-size: 14px;
        }
      }

      label {
        color: $primary-color !important;
      }
    }

    .fieldRow {
      @include flex(row, space-between, flex-start);

      width: 100%;

      @media (max-width: $mobile) {
        flex-direction: column
      }

      .textFieldContainer {
        width: 55% !important;

        @media (max-width: $mobile) {
          width: 100% !important;
        }
      }

      .textFieldContainer:last-child {
        width: 40% !important;

        @media (max-width: $mobile) {
          width: 100% !important;
        }
      }
    }
    .buttonsContainer {
      @include flex(row, flex-end, flex-end);
      width: 100%;
      margin: 20px 0;

      .buttonSave {
        *{
          color: white;
        }
      }
      .buttonCancel {
        *{
          color: $black;
        }
      }
    }
  }
}
