@import "../../../../../styles/colors";
@import "../../../../../styles/flex";
@import "../../../../../styles/dimentions";
@import "../../../../../styles/card";


  .title {
    color: $primary-color;
    padding: 0 10px;
  }

  .registerForm {
    width: 100%;
  }
  .textFieldContainer {
    width: 100%;

    * {
      border-color: $primary-color !important;
      @media(max-width: $mobile) {
        font-size: 14px;
      }
    }

    label {
      color: $primary-color !important;
    }
  }

  .fieldRow {
    @include flex(row, space-between, flex-start);

    width: 100%;

    @media (max-width: $mobile) {
      flex-direction: column
    }

    .textFieldContainer {
      width: 45% !important;

      @media (max-width: $mobile) {
        width: 100% !important;
      }
    }

  }

  .extraUsersInfo {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    justify-content: left;

    .selectOptionsFormControl {
      padding: 0 !important;	
  }
    
    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
  
  }

