@import "../../styles/colors";

.selectOptionsFormControl {
  * {
    border-color: $primary-color !important;
    color: $black;
  }

  margin-top: 16px;
  margin-bottom: 8px;
  width: 100%;
  margin: 16px 0 8px;
  padding: 18.5px 14px;

  label {
		color: $primary-color !important;
	}
}
