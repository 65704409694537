@import "../../styles/dimentions";
@import "../../styles/flex";

.cardInfo {
    @include flex(column, flex-start, flex-start);

    h3 {
        font-size: 18px;

        @media (max-width: $tablet) {
            font-size: 15px;
        }

        @media (max-width: $mobile) {
            font-size: 12px;
        }
    }

    p {
        font-size: 15px;

        @media (max-width: $tablet) {
            font-size: 12px;
        }

        @media (max-width: $mobile) {
            font-size: 9px;
        }
    }

}