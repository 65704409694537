@import "../../../styles/flex";
@import "../../../styles/colors";
@import "../../../styles/card";
@import "../../../styles/dimentions";

.homeContainer {
    max-width: 1200px;
    margin: 0 auto;

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    .row {
        display: flex;
        flex-direction: row;
        padding: 20px;


        @media (max-width: $tablet) {
            flex-direction: column;
            padding-bottom: 0;
        }
    }

    .componentButtonGroups {
        @include flex(column, flex-start, flex-start);
        width: 40%;
        padding-right: 10px;

        @media (max-width: $tablet) {
            @include flex(row, center, center);
            flex-direction: column;
            width: auto;
            padding: 20px 0 0 0
        }

        @media (max-width: $mobile) {
            padding: 10px 0 0 0
        }
    }

    .componentButtonGroup {
        @extend %base_card;

        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 2px 10px;

        @media (max-width: $tablet) {
            @include flex(row, flex-start, flex-end);
            margin: 10px 0;
        }

        @media (max-width: $mobile) {
            width: 100%;
        }

        .componentButton {
            background-color: transparent;

            .descriptionLabel {
                color: $primary-color;
                font-weight: bold;
            }

            &:first-child {
                margin-left: 0;
            }

            @media (max-width: $tablet) {
                max-width: 320px;
                width: 100%;
            }
        }
    }
}
.block {
    @extend .homeContainer;
    *{
       pointer-events: none; 
    }

    opacity: 0.5;
    cursor: not-allowed;
}

.progressContainer {
    z-index: 99999;
}
