@import "./flex";
@import "./dimentions";
@import "./colors";


%card {

    @include flex(column, start, center);
    box-sizing: border-box;
    margin: 0;
    padding: 16px;
    background-color: white;
    border-radius: $border-radius;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2),
        0px 1px 1px 0px rgba(0,0,0,0.14),
        0px 2px 1px -1px rgba(0,0,0,0.12);
}

%base_card {
    background-color: #fff;
    box-shadow: 1px 1px 3px $light-gray;
    border-radius: 4px;
    color: $gray;
    overflow: hidden;
}
