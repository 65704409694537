@import "../../styles/colors";
@import "../../styles/dimentions";
@import "../../styles/flex";

.componentButton {
	@include flex(column, space-around, flex-end);

	box-sizing: border-box;
	width: 180px;
	height: 120px;

	padding: 10px;

	.descriptionDate {
		@include flex(row, flex-start, flex-start);

		width: 100%;
		padding: 0 5px;

		font-size: 14px;
		color: $gray;
	}

	.descriptionLabel {
		@include flex(row, center, center);

		width: 100%;
		padding: 0;
		margin: 5px 0;

		font-size: 22px;
		color: $primary-color;
		font-weight: bold;
	}

	.componentButtonFooter {
		@include flex(row, space-between, center);
		width: 100%;
	}

	.componentButtonFooterData {
		margin: 0;
		padding: 0;
		font-size: 14px;
		color: $gray;
	}
	.percentage {
		@include flex(row, center, center);
		padding: 0;

		align-self: flex-end;
		font-size: 14px;
		color: $gray;

		.loading {
			@include flex(row, center, center);

			width: 15px;
			height: 15px;
		}
		.arrowIcon {
			padding: 0;
			margin: 0;
			width: 15px;
			height: 10px;
			fill: $gray;
		}
	}
}
