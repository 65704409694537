@import "../../styles/colors";

.dashboardLayoutContainer {
	background-color: $background-color;

	width: 100%;
	height: 100vh;
	padding: 0;

	margin: 0;

	&ChildrenContainer {
		min-height: 80%;
	}


}
.dashboardLayoutContainerLoading {
	@extend .dashboardLayoutContainer;

	opacity: 0.5;
	cursor: not-allowed;

	* {
		pointer-events: none;
	}
}
