@import "../../styles/dimentions";

.inputsContainer {
    width: 300px;

    @media(max-width: $tablet) {
        width: 250px;
    }

    @media (max-width: $mobile) {
        width: 200px;
    }
}