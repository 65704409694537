@import "../../../../styles/colors";
@import "../../../../styles/flex";
@import "../../../../styles/dimentions";
@import "../../../../styles/card";

.containerEdit {
  @include flex(column, center, center);

  width: 100%;

  .userForm {
    @include flex(column, center, center);

    @extend %card;
    width: 80%;
    padding: 0 20px;
    margin-bottom: 40px;

    @media (max-width: $tablet) {
      width: 100%;
    }
    @media (max-width: $mobile) {
      width: 100%;
    }

      .buttonsContainer {
        @include flex(row, flex-end, flex-end);
        width: 100%;
        margin: 30px 0;

        .buttonSave {
          *{
            color: white;
          }
        }
        .buttonCancel {
          *{
            color: $black;
          }
        }
      }
  }
}
