@import "../../styles/flex";
@import "../../styles/colors";


.changeDateDialog {
    max-width: 400px;
    .itemAvaliabilityDialogTitle {
        margin-bottom: 10px;
    }
    .itemAvaliabilityDialogContent {
        @include flex(column, flex-start, flex-start);
        width: 80%;
        h6 {
            margin: 0;
        }
        .itemAvaliabilityDialogLabel {
            margin-top: 10px;
            font-size: 16px;
            width: 100%;
            color: $text-color;
            font-weight: 500;
        }
        .itemAvaliabilityDialogTextField {
            box-sizing: border-box;
            margin-top: 10px;
            width: 100%;
            border:1px solid grey;
            border-radius: 10px;
            margin-top: 5px;
            color: black;
            padding: 10px;
            box-sizing: border-box;
            font-size: 16px;
            outline: 0;
        }
    }
}
