@import "../../styles/dimentions";
@import "../../styles/flex";
@import "../../styles/card";

.requestRow {
	@include flex(row, flex-start, flex-end);
	flex-wrap: wrap;
	width: 100%;
	margin-bottom: 30px;
}
.requestTreeColumns {
	box-sizing: border-box;
	width: calc(100% / 3);

;

	@media (max-width: $tablet) {
		width: 50%;
	}
	@media (max-width: $mobile) {
		width: 100%;
	}

}
.requestContainerImage {
	@include flex(row, flex-start, center);

	box-sizing: border-box;
	flex-grow: 1;
	width: calc(100% / 3);

	@media (max-width: $tablet) {
		@include flex(row, flex-start, flex-start);

		width: 100%;
		margin-bottom: 15px;
	}
}
.requestImageAvatar {
	border-radius: 50%;
	width: 150px;
	height: 150px;
	overflow: hidden;
	position: relative;
	img {
		position: absolute;
		bottom: 0;
		width: 100%;
	}
	@media (max-width: $tablet) {
		width: 150px;
		height: 150px;
	}
}
