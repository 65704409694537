@import "../../../../styles/dimentions";
@import "../../../../styles/flex";
@import "../../../../styles/colors";
@import "../../../../styles/card";

.containerFilterText{
    width: 100%;
    padding: 10px 0;

    .filterTextRow {
        @include flex(row, flex-start, center);
        width: 100%;

        .periodTextSelect {
            padding: 0 15px 0 0;            text-decoration: underline;
            text-decoration-color: $light-gray;
        }
        .periodTextClear {
            font-weight: bold;
            color: $error-color;
            cursor: pointer;
        }
       
    }
}
.popoverComponent {
    left: 30px !important;
}
.popoverFilterTextContainer {
    @include flex(column, flex-start, flex-start);
    padding: 20px;
    min-width: 200px;
    min-height: 180px;

    .popoverButton {
        width: 100%;
         margin-top: 10px;
    
        .periodButtonConfirm {
            width: 100%;
            background: $primary-color;
            
            .MuiButton-label {

                color: $white;
                font-weight: bold;
            }
           
        }
    }


}
