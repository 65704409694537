@import "../../../../styles/flex";
@import "../../../../styles/card";
@import "../../../../styles/colors";
@import "../../../../styles/dimentions";

.offerCreateOrEdit {
	@include flex(column, flex-start, flex-start);

	width: 100%;

	&Wrapper {
		@include flex(column, flex-start, flex-start);
		@extend %card;
		
		width: 80%;
		box-sizing: border-box;
		padding: 30px;
		margin: 30px;

		@media (max-width: $mobile) {
			width: 100%;
			margin: 0;
		}

		@media (max-width: $tablet) {
			width: 100%;
			margin: 0;
		}
	
		&Title {
			font-weight: bolder;
		}
	
		.offerFormContainer {
			width: 100%;
	
			h2 {
				font-size: 18px;
			}
			.rowTwoEnum {
				@include flex(row, space-between, flex-start);
				width: 100%;
				flex-wrap: wrap;
				.enum {
					width: 47%;
					
					*{
						label {
							color: $primary-color !important;
						}
					}
					.selectOptionsFormControl {
						padding: 0 !important;	
						

					}

				}
			}
		}
	
		.buttonsContainer {
			@include flex(row, flex-end, center);
	
			margin-top: 30px;
			width: 100%;

			button {
				margin-left: 20px;
			}
		}
	}

}
