@import "../../../../../styles/flex";
@import "../../../../../styles/card";
@import "../../../../../styles/colors";
@import "../../../../../styles/dimentions";


.bannerSectionTitle {
	font-weight: bolder;
	margin-top: 35px;
	margin-bottom: 10px;
	color: $primary-color;
}

.rowTwoEnum {
    @include flex(row, space-between, flex-start);
    width: 100%;
    .enum {
        width: 45%;

        .selectOptionsFormControl {
            padding: 0 !important;	
        }
    }
}