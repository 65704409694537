@import "../../styles/colors";

.textFieldContainer {
    * {
        border-color: $primary-color !important;
    }

    label {
        color: $primary-color !important;
    }
}

.textFieldContainer {
    width: 100%;
    &ErrorTypography {
        color: red;
    }
}
