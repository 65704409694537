@import "../../styles/colors";
@import "../../styles/dimentions";

.normalButton {
	cursor: pointer;

	font-size: 18px;
	font-weight: bold;
	text-align: center;
	border: none;
	box-sizing: border-box;
	padding: 10px 20px;
	border-radius: $border-radius;
	color: $white;
	outline: none;
	background-color: $primary-color;

	&[disabled] {
		cursor: not-allowed;

		background-color: $light-gray;
		color: $gray;
	}
}
