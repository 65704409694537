// MARK: General
$white: #FFFFFF;
$light-gray: #D0D0D0;
$gray: #818181;
$black: #000000;

// MARK: Logo
$primary-color: #FC6371;
$primary-color50: rgba(252, 99, 113, 0.5);
$secondary-color: #7943fc;
$third-color: #4086fc;

// MARK: Components
// Text
$dark-text-color: $black;
$text-color: $gray;

// Background
$background-color: #F8F8F8;

// Error
$error-color: #c0392b;

// Shadow
$shadow-color: rgba(0, 0, 0, 0.16);

// Separator
$separator-color: rgba(0, 0, 0, 0.10);
